import './Intro.css';
import '../../../styles/Global.css';
import FadeInText from '../FadeInText/FadeInText';

function Intro() {
    return (
        <div className="Intro">
            <div className='IntroFirst'>
                <div className='Column'>
                    <FadeInText text="BUSINESS DIGITALIZATION" delay={3000} />
                </div>
                <div className='Column'>
                    <FadeInText text="BUSINESS INTELLIGENCE" delay={4500} />
                </div>
            </div>
            <div className='IntroSecond'>
                <FadeInText class="lightblue" text="SUPERIOR IT SOLUTIONS" delay={9000} />           
            </div>
            <div className='IntroThird'>
                <div className='Column'>
                    <FadeInText text="DIGITAL INOVATION" delay={6000} />
                </div>
                <div className='Column'>
                    <FadeInText text="CONSULTING SERVICES" delay={7500} />
                </div>
            </div>
        </div>
    )
}

export default Intro; 
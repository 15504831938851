import './CodeEditor.css';
import React, { useState, useEffect, useRef } from 'react';

function CodeEditor() {
  const editorCode = (
    <div className='TypingText'>
      <span><span style={{ color: 'var(--light-blue)' }}>import</span> skills</span><br />
      <span><span style={{ color: 'var(--light-blue)' }}>import</span> knowledge</span><br />
      <span><span style={{ color: 'var(--light-blue)' }}>import</span> efficiency</span><br /><br />
      <span style={{ color: 'var(--light-blue)' }}>def</span> create_smart_solution():<br />
      <div style={{ paddingLeft: '20px'}}>
        company_name = <span style={{ color: '#85C54B' }}>"superiorITy - IT solutions"</span><br />
        company_description = <span style={{ color: '#85C54B' }}>"At superiorITy we specialize in creating bespoke and efficient software solutions."</span>.format(company_name)<br />
        services = [<span style={{ color: '#85C54B' }}>"Custom software development"</span>, <span style={{ color: '#85C54B' }}>"Web application development"</span>, <span style={{ color: '#85C54B' }}>"Mobile app development"</span>]<br />
        expertise = [<span style={{ color: '#85C54B' }}>"Agile development methodologies"</span>, <span style={{ color: '#85C54B' }}>"Business intelligence"</span>, <span style={{ color: '#85C54B' }}>"Data analytics"</span>]<br />
        result = "{}{}{}".format(company_description, services_text, expertise_text)<br />
        return result
      </div><br/>
      <span style={{ color: 'var(--light-blue)' }}>create_smart_solution()</span>
    </div>
  );

  const [code, setCode] = useState(editorCode);
  const editorRef = useRef(null);

  const handleChange = () => {
    const newCode = editorRef.current.innerText;
    setCode(newCode);
  };

  useEffect(() => {
    const editor = editorRef.current;

    const updateRowNumbers = () => {
      const lineNumberContainer = document.querySelector('.LineNumberContainer');
      lineNumberContainer.innerHTML = '';

      for (let i = 0; i < 19; i++) {
        const lineNumber = document.createElement('span');
        lineNumber.textContent = i + 1;
        lineNumberContainer.appendChild(lineNumber);
      }
    };

    editor.addEventListener('input', handleChange);
    editor.addEventListener('scroll', updateRowNumbers);
    updateRowNumbers();

    return () => {
      editor.removeEventListener('input', handleChange);
      editor.removeEventListener('scroll', updateRowNumbers);
    };
  }, []);

  return (
    <div className='CodeEditor'>
      <div className='LightblueBox'></div>
      <div className='CodeEditorWrapper'>
        <div className='LineNumberContainer'></div>
        <div className='Editor' ref={editorRef}>
          {code}
        </div>
        <div className='CodeEditorText'>
        Whether you require a complex enterprise-level system, a web application, or a custom-tailored software, we have the expertise to turn your vision into reality. Our agile development methodologies ensure that we adapt to your evolving needs and deliver results within stipulated timeframes.<br/><br />Our solutions are designed to maximize your return on investment and provide a competitive advantage in today's fast-paced digital landscape.<br/> <br/>Experience our commitment to excellence, personalized approach, and unwavering focus on developing solutions that precisely meet your needs.
        </div>
      </div>
      <div className='LightblueBox'></div>
    </div>
  );
}

export default CodeEditor;


import './TechnologyStack.css'

function TechnologyStack() {
    return (
        <div className='TechnologyStack'>
            <div className='TechnologyStackDescription1'>
                At our company, we leverage a cutting-edge and robust technology stack to power our projects and 
                deliver exceptional solutions to our clients. 
            </div>
            <div className='TechnologyStackWrapper'>
                <div className='TechnologyInsideWrapper'>
                    <div className='HexagonHide'></div>
                    <div className='HexagonHide'></div>
                    <div className='HexagonHide'></div>
                    <div className='HexagonHide'></div>
                    <a rel="noreferrer" target='_blank'href='https://www.microsoft.com/en-us/sql-server/sql-server-2022'><div className='Hexagon'><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/microsoftsqlserver/microsoftsqlserver-plain-wordmark.svg" alt='sqlserver'/></div></a>
                    <a rel="noreferrer" target='_blank'href='https://www.mysql.com/'><div className='Hexagon'><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg" alt='mysql'/></div></a>
                    <a rel="noreferrer" target='_blank'href='https://www.oracle.com/'><div className='Hexagon'><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/oracle/oracle-original.svg" alt='oracle' /></div></a>
                    <a rel="noreferrer" target='_blank'href='https://learn.microsoft.com/en-us/dotnet/csharp/'><div className='Hexagon'><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg" alt='csharp'/></div></a>
                    <a rel="noreferrer" target='_blank'href='https://learn.microsoft.com/en-us/dotnet/core/introduction'><div className='Hexagon'><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/dotnetcore/dotnetcore-original.svg" alt='.net-core'/></div></a>
                    <a rel="noreferrer" target='_blank'href='https://developer.mozilla.org/en-US/docs/Web/HTML'><div className='Hexagon'><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg" alt='html5'/></div></a>
                    <a rel="noreferrer" target='_blank'href='https://www.python.org/'><div className='Hexagon'><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg" alt='python'/></div></a>
                    <a rel="noreferrer" target='_blank'href='https://developer.mozilla.org/en-US/docs/Web/JavaScript'><div className='HexagonSmaller'><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" alt='js'/></div></a>
                    <a rel="noreferrer" target='_blank'href='https://www.typescriptlang.org/'><div className='HexagonSmaller'><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg" alt='ts'/></div></a>
                    <a rel="noreferrer" target='_blank'href='https://legacy.reactjs.org/'><div className='Hexagon'><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" alt='react'/></div></a>
                </div>
            </div>
            <div className='TechnologyStackDescription2'>
                Our carefully selected technology stack comprises a set of powerful tools, 
                frameworks, and languages that work seamlessly together, enabling us to create innovative and scalable software applications.
            </div>
        </div>
        
    );
}

export default TechnologyStack;
import './LoadingContent.css';
import '../../../styles/Global.css';
import logo from '../../../images/superiorITy-biglogo.png';
import { useState, useEffect } from 'react';

function LoadingContent() {
  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
        setShowOverlay(false);
    }, 2000);

    return () => {
        clearTimeout(timeoutId);
    };
  }, []);


  return (
    <div className={`LoadingOverlay${showOverlay ? '' : ' hidden'}`}>
        <div className='LoadingContent'>
            <img className="CoverLogo" src={logo} alt="superiorITy-biglogo" />
        </div>
    </div>

  );
}

export default LoadingContent;

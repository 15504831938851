import './Header.css';
import '../../../styles/Global.css';
import logo from '../../../images/superiorITy.png';

function Header() {
  return (
    <div className="Header">
      <header className="App-header">
        <img className="Logo" src={logo} alt="superiorITy-Logo" />
        <ul className='MenuList'>
          <li>HOME</li>
          <li>ABOUT US</li>
          <li>DEVELOPMENT</li>
          <li>SERVICES</li>
          <li>CONTACT</li>
        </ul>
      </header>
    </div>
  );
}

export default Header;

import './Footer.css'
import whiteLogo from '../../../images/white-logo.png';
import facebook from '../../../images/facebook.png';
import instagram from '../../../images/instagram.png';
import linkedin from '../../../images/linkedin.png';

function Footer() {
    let year = new Date().getFullYear();
    return(
        <div className='Footer'>
            <div className='FooterColumns'>
                <div className='FooterColumn1'></div>
                <div className='FooterColumn2'>
                    <img className='FooterLogo' src={whiteLogo} alt='superiority-logo'/>
                </div>
                <div className='FooterColumnEmpty'>              
                </div>
                <div className='FooterColumn3'>
                    <p>Partnerships</p>
                    <p>Development</p>
                    <p>Services</p>
                    <p>Blog</p>
                    <p>Contact us</p>
                </div>
                <div className='FooterColumn4'>
                    <p>Follow us</p>
                    <p>
                        <img src={facebook} alt='facebook-icon' /> 
                        <a rel='noreferrer' target='_blank' href='https://www.instagram.com/superiority.ba/'><img src={instagram} alt='instagram-icon' /></a>
                        <img src={linkedin} alt='linkedin-icon' /> 
                    </p>
                </div>
                <div className='FooterColumn5'></div>
            </div>
            <div className='FooterCopyright'>
                <div className='FooterCopyright1'></div>
                <div className='FooterCopyright2'>
                    <p>© Copyrights {year} | All rights reserved</p>
                </div>
                <div className='FooterCopyrightEmpty'></div>
                <div className='FooterCopyright3'></div>
                <div className='FooterCopyright4'>
                    <p>Made by <b>superiorITy</b></p>
                </div>
                <div className='FooterCopyright5'></div>
            </div>
        </div>
    );
}

export default Footer;
import React from 'react';
import ReactDOM from 'react-dom/client';
import Header from './pages/components/Header/Header';
import LoadingContent from './pages/components/Loader/LoadingContent';
import Intro from './pages/components/Intro/Intro';
import About from './pages/components/About/About'
import Footer from './pages/components/Footer/Footer';
import TechnologyStack from './pages/components/TechnologyStack/TechnologyStack';
import CodeEditor from './pages/components/CodeEditor/CodeEditor';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <Header />
    <LoadingContent />
    <Intro />
    <About />
    <TechnologyStack />
    <CodeEditor />
    <Footer />
  </React.Fragment>
);

import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './FadeInText.css'; // Create a CSS file for styling

class FadeInText extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showText: false
      };
    }
  
    componentDidMount() {
      // Trigger the text fade-in after a delay (if needed)
      setTimeout(() => {
        this.setState({ showText: true });
      }, this.props.delay);
    }
  
    render() {
      return (
        <div className={this.props.class}>
          <CSSTransition
            in={this.state.showText}
            timeout={1000} // Adjust the transition duration as needed
            classNames="fade"
            unmountOnExit
          >
            <h1>{this.props.text}</h1>
          </CSSTransition>
        </div>
      );
    }
  }

  export default FadeInText;
  
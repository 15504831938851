import './About.css';
import meetingImage from '../../../images/meeting.jpg';

function About() {
    return(
        <div>
            <div className='LightblueBox'></div>
            <div className='About'>
                <div className='MeetingImage'>
                    <img src={meetingImage} alt='meeting' />
                </div>
                <div className='AboutText'>
                    <h2>Who are we?</h2>
                    <p>We are team of software engineers and technical architects skilled in creating customized software solutions, driving business intelligence, and facilitating digitalization initiatives. </p>
                    <p>With our extensive knowledge of technology stacks and our commitment to delivering high-quality solutions, we empower businesses to thrive in today's digital landscape.</p>
                </div>
            </div>
            <div className='LightblueBox'></div>
        </div>
        
    );
}

export default About;